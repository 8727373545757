<template>
  <div class="tc-portal">
    <div class="layout">
      <!-- 搜索框 -->
      <div class="portal-query" v-if="isSearch !=3">
        <div class="qeury">
          <input type="text" placeholder="请输入要搜索的文章" v-model="searchIpuit" @keyup.enter="showRes" />
          <i @click="showRes" class="fa fa-search"></i>
        </div>
      </div>
      <!-- 分类 -->
      <div class="portal-class" v-if="isSearch ==1">
        <ul v-if="!allclassData">
          <li
            v-for="(item,key) in classData.slice(0,7)"
            :key="key"
            @click="loadArticaleData(item.id,item.title)"
            :class="classid == item.id?'isactive':''"
          >{{item.title}}</li>
          <li @click="showallClass()" class="more">更多</li>
        </ul>
        <ul v-if="allclassData">
          <li
            v-for="(item,key) in classData.slice(0,8)"
            :key="key"
            @click="loadArticaleData(item.id,item.title)"
            :class="classid == item.id?'isactive':''"
          >{{item.title}}</li>
        </ul>
        <el-collapse-transition>
          <ul v-if="allclassData">
            <li
              v-for="(item,key) in classData.slice(8,classData.length)"
              :key="key"
              @click="loadArticaleData(item.id,item.title)"
              :class="classid == item.id?'isactive':''"
            >{{item.title}}</li>
            <li @click="showallClass()" class="more">收起</li>
          </ul>
        </el-collapse-transition>
      </div>
      <!-- 内容 -->
      <div class="portal-main" v-if="isSearch ==1">
        <!-- 热门 -->
        <div class="hot">
          <div class="hot_titie">
            <p>
              <span v-if="className0 ==''">今日</span>
              <span>{{className0}}-</span>
              <span>热门推荐</span>
            </p>
          </div>
          <div class="hot_more">
            <p @click="showMore">
              <i class="fa fa-angle-double-right"></i>更多
            </p>
          </div>
        </div>
        <div class="msg" v-if="hotarticale.length <=0">
          <p>暂时，没有更多文章</p>
        </div>
        <ul>
          <li v-for="(item,key) in hotarticale.slice(0,5)" :key="key" @click="goInfo(item.id)">
            <div class="item">
              <div class="banner">
                <img :src="item.bannerurl" alt />
              </div>
              <div class="content">
                <div class="content_title">
                  <p>{{item.title}}</p>
                </div>
                <div class="content_foot">
                  <div class="time">
                    <p>{{item.create_time}}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- 最新文章 -->
        <div class="new">
          <p>最近发布</p>
        </div>
        <ul
          class="infinite-list"
          v-infinite-scroll="rollLoad"
          infinite-scroll-delay="500"
          :infinite-scroll-immediate="false"
        >
          <li v-for="(item,key) in articaleData" :key="key" @click="goInfo(item.id)">
            <div class="item">
              <div class="banner">
                <img :src="item.bannerurl" alt />
              </div>
              <div class="content">
                <div class="content_title">
                  <p>{{item.title}}</p>
                </div>
                <div class="content_foot">
                  <div class="time">
                    <p>{{item.create_time}}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--底部提示-->
        <div class="msg">
          <p v-if="articaleData.length <=0 || isarticale">暂时，没有更多文章</p>
          <p v-else>
            <i class="el-icon-loading"></i>
            <span>正在加载</span>
          </p>
        </div>
      </div>
      <!-- 搜索结果 -->
      <div class="query-main queryRes" v-if="isSearch ==2">
        <div class="new">
          <div class="new_title">
            <p>
              搜索结果
              <i class="fa fa-hand-o-down"></i>
            </p>
          </div>
          <div class="new_blak" @click="hideRes">
            <i class="fa fa-reply"></i>
            返回
          </div>
        </div>
        <ul
          class="infinite-list"
          v-infinite-scroll="rollLoad"
          infinite-scroll-delay="500"
          :infinite-scroll-immediate="false"
        >
          <li v-for="(item,key) in searchData" :key="key" @click="goInfo(item.id)">
            <div class="item">
              <div class="banner">
                <img :src="item.bannerurl" alt />
              </div>
              <div class="content">
                <div class="content_title">
                  <p>{{item.title}}</p>
                </div>
                <div class="content_foot">
                  <div class="time">
                    <p>{{item.create_time}}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--底部提示-->
        <div class="msg">
          <p v-if="searchData.length <=0 || issearchData">暂时，没有更多文章</p>
          <p v-else>
            <i class="el-icon-loading"></i>
            <span>正在加载</span>
          </p>
        </div>
      </div>
      <!-- 更多热门推荐 -->
      <div class="more-main moreRes" v-if="isSearch ==3">
        <!-- 热门 -->
        <div class="hot">
          <div class="hot_titie">
            <p>
              更多热门推荐
              <i class="fa fa-hand-o-down"></i>
            </p>
          </div>
          <div class="hot_more">
            <p @click="hideRes">
              <i class="fa fa-reply"></i>
              返回
            </p>
          </div>
        </div>
        <div class="msg" v-if="hotarticale.length <=0">
          <p>暂时，没有更多文章</p>
        </div>
        <ul
          class="infinite-list"
          v-infinite-scroll="rollLoad"
          infinite-scroll-delay="500"
          :infinite-scroll-immediate="false"
        >
          <li v-for="(item,key) in morehotData" :key="key" @click="goInfo(item.id)">
            <div class="item">
              <div class="banner">
                <img :src="item.bannerurl" alt />
              </div>
              <div class="content">
                <div class="content_title">
                  <p>{{item.title}}</p>
                </div>
                <div class="content_foot">
                  <div class="time">
                    <p>{{item.create_time}}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--底部提示-->
        <div class="msg">
          <p v-if="hotarticale.length <=0 || ishotarticale">暂时，没有更多文章</p>
          <p v-else>
            <i class="el-icon-loading"></i>
            <span>正在加载</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getuserartclass, getuserart } from "@/api/article.js";
import { log } from "util";
export default {
  data() {
    return {
      classData: [],
      articaleData: [],
      isarticale: false, //控制 最近发布 是否显示正在加载
      hotarticale: [],
      ishotarticale: false, //控制 热门 是否显示正在加载
      searchData: [],
      issearchData: false, //控制 搜索 是否显示正在加载
      morehotData: [],
      allclassData: false,
      classid: 0,
      page: 0,
      num: 0,
      isZ: 0,
      searchIpuit: "",
      className0: "",
      isSearch: 1, // 控制页面展示的主要内容 1-热门、最近发布   2-搜索  3-更多热门
      searchPage: 0,
      searchNum: -1,
      morehotpage: 0
    };
  },
  created() {
    if (this.$route.query.keywords) {
      this.searchIpuit = this.$route.query.keywords;
      this.showRes();
    } else {
      this.init();
      this.loadArticaleData();
    }
  },
  mounted() {},
  methods: {
    //   页面数据初始化
    init() {
      let classList = {
        pid: 0,
        page: 0,
        pagesize: 999
      };
      getuserartclass(classList).then(res => {
        this.classData = res.data;
      });
    },
    // 控制显示更多
    showallClass() {
      if (this.allclassData) {
        this.allclassData = false;
      } else {
        this.allclassData = true;
      }
    },
    // 加载分类下的热门文章
    loadArticaleData(id, name = "") {
      this.isarticale = false;
      this.page = 0;
      this.classid = id;
      this.className0 = name;
      let data = {
        page: this.page,
        pagesize: 9999,
        class: id
      };
      getuserart(data).then(res => {
        this.articaleData = res.data.slice(0, 5);
        if (res.data.length <= 5) {
          this.isarticale = true;
        }
        let arr = [];
        res.data.forEach(v => {
          if (v.hot == 1) {
            arr.push(v);
          }
        });
        this.hotarticale = arr;
        this.num = res.num;
      });
    },
    // 滚动加载
    load() {
      let data = {
        page: this.page + 1,
        pagesize: 5,
        class: this.classid
      };
      getuserart(data).then(res => {
        let arr = [];
        if (res.code == 400200) {
          if (res.data.length <= 0) {
            this.isarticale = true;
          } else {
            this.isarticale = false;
          }
          res.data.forEach(v => {
            this.articaleData.push(v);
          });
          this.page++;
        }
      });
    },
    // 赞
    fun_z() {
      if (this.isZ == 0) {
        this.isZ = 1;
      } else {
        this.isZ = 0;
      }
    },
    // 跳转文章详情
    goInfo(id) {
      this.isSearch = 0;
      this.$router.push(`/answer_home/info?id=${id}`);
    },
    //显示 搜索
    showRes() {
      this.issearchData = false;
      this.searchPage = 0;
      this.searchData = [];
      this.isSearch = 2;
      this.search();
    },
    // 搜索
    search() {
      let data = {
        page: this.searchPage,
        pagesize: 10,
        keywords: this.searchIpuit
      };
      getuserart(data).then(res => {
        let arr = [];
        if (res.code == 400200) {
          if (res.data.length <= 0) {
            this.issearchData = true;
          }
          res.data.forEach(v => {
            this.searchData.push(v);
          });
          this.searchNum = res.num * 1;
          this.searchPage++;
        }
      });
    },
    // 关闭搜索，返回
    hideRes() {
      if (this.$route.query.keywords) {
        this.$router.go(-1);
      } else {
        this.isSearch = 1;
        this.searchIpuit = "";
      }
    },
    // 显示更多热门推荐
    showMore() {
      this.ishotarticale = false;
      this.morehotData = [];
      this.morehotpage = 0;
      this.isSearch = 3;
    },
    // 加载热门推荐
    LoadMore() {
      let data = {
        page: this.morehotpage,
        pagesize: 10,
        hot: 1,
        class: this.classid
      };
      getuserart(data).then(res => {
        let arr = [];
        if (res.code == 400200) {
          if (res.data.length <= 0) {
            this.ishotarticale = true;
          }
          res.data.forEach(v => {
            this.morehotData.push(v);
          });
          this.morehotpage++;
        }
      });
    },
    // 滚动加载
    rollLoad() {
      if (this.isSearch == 1) {
        if (!this.isarticale) {
          this.load();
        }
      } else if (this.isSearch == 2) {
        if (!this.issearchData) {
          this.search();
        }
      } else if (this.isSearch == 3) {
        if (!this.ishotarticale) {
          this.LoadMore();
        }
      }
    }
  },
  watch: {
    classid: function() {
      this.hotarticale = [];
      this.articaleData = [];
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "answer_info") {
      to.meta.ifDoFresh = false;
      next();
    }
    if (from.meta.ifDoFresh) {
      next();
      location.reload();
      from.meta.ifDoFresh = false;
      return;
    }
    next();
  },
  activated() {
    //开启了keepAlive:true后再次进入，以前的搜索条件和页数都不会变，无论什么情况都调用一下获取数据的接口，这样就能得到当前搜索条件和页数的最新数据
    if (this.$route.meta.ifDoFresh) {
      //重置ifDoFresh
      this.$route.meta.ifDoFresh = false; //获取列表数据方法第一参数为是否重置搜索条件和页数
      this.init();
    } else {
      this.isSearch = 1;
      // this.loadUsers();
      // this.init();
    }
  }
};
</script>
<style lang="less">
.tc-portal {
  li {
    list-style: none;
  }
  .layout {
    width: 100%;
    min-width: 320px;
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    .portal-query {
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 10px;
      .qeury {
        height: 30px;
        width: 70%;
        margin: 0 auto;
        background: #ffffff;
        border: #7c7c7c 1px solid;
        border-radius: 20px;
        position: relative;
        input {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          background: transparent;
          padding: 0 20px;
        }
        .fa-search {
          position: absolute;
          top: 6px;
          right: -25px;
          font-size: 20px;
          color: rgb(3, 71, 73);
        }
      }
    }
    .portal-class {
      background: rgb(214, 234, 238);
      border-radius: 10px;
      padding: 3px 0;
      border: 1px solid rgb(161, 160, 160);
      box-shadow: rgb(110, 110, 110) 2px 3px 3px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 12.5%;
          text-align: center;
          font-size: 16px;
          padding: 8px 0;
        }
        .more {
          font-weight: 700;
          font-size: 16px;
        }
      }
      .isactive {
        color: cornflowerblue;
        font-weight: 700;
      }
    }
    .portal-main {
      padding-top: 20px;
      .item {
        height: 150px;
        display: flex;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: solid #dfdbdb 1px;
        .banner {
          flex: 2;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
        .content {
          flex: 5;
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          .content_title {
            flex: 5;
            font-size: 18px;
            p {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          .content_foot {
            flex: 2;
            display: flex;
            .time {
              flex: 1;
              font-size: 12px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-end;
              color: #7c7c7c;
            }
          }
        }
      }
      .hot {
        padding-bottom: 5px;
        font-weight: 700;
        color: red;
        border-bottom: solid red 2px;
        display: flex;
        justify-content: space-between;
        .hot_titie {
        }
        .hot_more {
        }
      }
      .new {
        font-weight: 700;
        padding: 20px 0 5px 0;
        border-bottom: solid black 2px;
      }
      .msg {
        text-align: center;
        padding: 10px;
      }
    }
    .query-main {
      .item {
        height: 150px;
        display: flex;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: solid #dfdbdb 1px;
        .banner {
          flex: 2;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
        .content {
          flex: 5;
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          .content_title {
            flex: 5;
            font-size: 18px;
            p {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          .content_foot {
            flex: 2;
            display: flex;
            .time {
              flex: 1;
              font-size: 12px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-end;
              color: #7c7c7c;
            }
          }
        }
      }
      .hot {
        padding-bottom: 5px;
        font-weight: 700;
        color: red;
        border-bottom: solid red 2px;
        display: flex;
        justify-content: space-between;
        .hot_titie {
        }
        .hot_more {
        }
      }
      .new {
        font-weight: 700;
        padding: 5px 0 5px 0;
        border-bottom: solid black 2px;
        display: flex;
        justify-content: space-between;
        .new_title {
          flex: 5;
        }
        .new_blak {
          flex: 1;
          text-align: center;
          color: rgb(238, 40, 40);
        }
      }
      .msg {
        text-align: center;
        padding: 10px;
      }
    }
    .more-main {
      .item {
        height: 150px;
        display: flex;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: solid #dfdbdb 1px;
        .banner {
          flex: 2;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
        .content {
          flex: 5;
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          .content_title {
            flex: 5;
            font-size: 18px;
            p {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          .content_foot {
            flex: 2;
            display: flex;
            .time {
              flex: 1;
              font-size: 12px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-end;
              color: #7c7c7c;
            }
          }
        }
      }
      .hot {
        padding-bottom: 5px;
        font-weight: 700;
        color: red;
        border-bottom: solid red 2px;
        display: flex;
        justify-content: space-between;
        .hot_titie {
        }
        .hot_more {
        }
      }
      .new {
        font-weight: 700;
        padding: 5px 0 5px 0;
        border-bottom: solid black 2px;
        display: flex;
        justify-content: space-between;
        .new_title {
          flex: 5;
        }
        .new_blak {
          flex: 1;
          text-align: center;
          color: rgb(238, 40, 40);
        }
      }
      .msg {
        text-align: center;
        padding: 10px;
      }
    }
  }
}
@media screen and (max-width: 812px) {
  .tc-portal {
    li {
      list-style: none;
    }
    .layout {
      width: 100%;
      min-width: 320px;
      max-width: 812px;
      margin: 0 auto;
      padding: 10px;
      box-sizing: border-box;
      .portal-query {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 10px;
        .qeury {
          height: 25px;
          width: 70%;
          margin: 0 auto;
          background: #ffffff;
          border: #7c7c7c 1px solid;
          border-radius: 20px;
          position: relative;
          input {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background: transparent;
            padding: 0 20px;
          }
          .fa-search {
            position: absolute;
            top: 4px;
            right: -25px;
            font-size: 20px;
            color: rgb(3, 71, 73);
          }
        }
      }
      .portal-class {
        background: rgb(214, 234, 238);
        border-radius: 10px;
        padding: 3px 0;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 25%;
            text-align: center;
            font-size: 14px;
            padding: 3px 0;
          }
          .more {
            font-weight: 700;
            font-size: 16px;
          }
        }
        .isactive {
          color: cornflowerblue;
          font-weight: 700;
        }
      }
      .portal-main {
        padding-top: 20px;
        .item {
          height: 80px;
          display: flex;
          padding-top: 8px;
          padding-bottom: 8px;
          border-bottom: solid #dfdbdb 1px;
          .banner {
            flex: 2;
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }
          .content {
            flex: 5;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            .content_title {
              flex: 5;
              font-size: 14px;
              p {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
            .content_foot {
              flex: 2;
              display: flex;
              .time {
                flex: 1;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                color: #7c7c7c;
              }
            }
          }
        }
        .hot {
          padding-bottom: 5px;
          font-weight: 700;
          color: red;
          border-bottom: solid red 2px;
          display: flex;
          justify-content: space-between;
          .hot_titie {
          }
          .hot_more {
          }
        }
        .new {
          font-weight: 700;
          padding: 20px 0 5px 0;
          border-bottom: solid black 2px;
        }
        .msg {
          text-align: center;
          padding: 10px;
        }
      }
      .query-main {
        .item {
          height: 80px;
          display: flex;
          padding-top: 8px;
          padding-bottom: 8px;
          border-bottom: solid #dfdbdb 1px;
          .banner {
            flex: 2;
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }
          .content {
            flex: 5;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            .content_title {
              flex: 5;
              font-size: 14px;
              p {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
            .content_foot {
              flex: 2;
              display: flex;
              .time {
                flex: 1;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                color: #7c7c7c;
              }
            }
          }
        }
        .hot {
          padding-bottom: 5px;
          font-weight: 700;
          color: red;
          border-bottom: solid red 2px;
          display: flex;
          justify-content: space-between;
          .hot_titie {
          }
          .hot_more {
          }
        }
        .new {
          font-weight: 700;
          padding: 5px 0 5px 0;
          border-bottom: solid black 2px;
          display: flex;
          justify-content: space-between;
          .new_title {
            flex: 5;
          }
          .new_blak {
            flex: 1.5;
            text-align: center;
            color: rgb(238, 40, 40);
          }
        }
        .msg {
          text-align: center;
          padding: 10px;
        }
      }
      .more-main {
        .item {
          height: 80px;
          display: flex;
          padding-top: 8px;
          padding-bottom: 8px;
          border-bottom: solid #dfdbdb 1px;
          .banner {
            flex: 2;
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }
          .content {
            flex: 5;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            .content_title {
              flex: 5;
              font-size: 14px;
              p {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
            .content_foot {
              flex: 2;
              display: flex;
              .time {
                flex: 1;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                color: #7c7c7c;
              }
            }
          }
        }
        .hot {
          padding-bottom: 5px;
          font-weight: 700;
          color: red;
          border-bottom: solid red 2px;
          display: flex;
          justify-content: space-between;
          .hot_titie {
          }
          .hot_more {
          }
        }
        .new {
          font-weight: 700;
          padding: 5px 0 5px 0;
          border-bottom: solid black 2px;
          display: flex;
          justify-content: space-between;
          .new_title {
            flex: 5;
          }
          .new_blak {
            flex: 1;
            text-align: center;
            color: rgb(238, 40, 40);
          }
        }
        .msg {
          text-align: center;
          padding: 10px;
        }
      }
    }
  }
}
</style>